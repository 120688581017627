import $ from "jquery";

const snapEngage = {};

// This function loads the SnapEngage library and triggers related
// updates to elements in the DOM.
//
// Usage
// -----
// If you want to use SnapEngage on a page with this Javascript,
// you can do the following:
//
// - Include this module into your JS file.
//   E.g. `import snapEngage from 'snap-engage';`
// - Call `snapEngage.start({proactiveChatEnabled: true});`.
//   See possible options below.
// - Add the 'js-open-snapengage-on-click' class to DOM elements that you
//   want to have open SnapEngage when clicked on (like "Live Chat" links).
// - Add the 'js-showhide-based-on-snapengage' class to DOM elements that
//   you want to hide when there are no SnapEngage agents online, and that
//   you want to show when there are agents online.
//
// Possible options
// -----
// - proactiveChatEnabled: true or false. If false, proactiveChat is disabled.
// - textSentToChat: string (optional) if present, this value is
//   sent to the SnapEngage agent when a chat starts.

function loadSnapEngageLibrary(onCompletionCallback) {
  const se = document.createElement("script");
  se.type = "text/javascript";
  se.async = true;
  se.src =
    "//commondatastorage.googleapis.com/code.snapengage.com/js/c18af344-199d-4258-b4c1-ae23016eeb0b.js";
  let done = false;

  const onSnapEngageLoad = function onSnapEngageLoad() {
    if (
      !done &&
      (!this.readyState || this.readyState === "loaded" || this.readyState === "complete")
    ) {
      done = true;
      // Place your SnapEngage JS API code below
      // SnapEngage.allowChatSound(true); // Example JS API: Enable sounds for Visitors.

      // --- begin AdWerx code ---
      onCompletionCallback();
      // --- end AdWerx code ---
    }
  };

  se.onload = onSnapEngageLoad;
  se.onreadystatechange = onSnapEngageLoad;

  const s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(se, s);
}

function fixBodyText() {
  $("body").css({ visibility: "visible" });
}

function pollSnapEngageAgentOnlineStatus() {
  if (window.SnapEngage) {
    window.SnapEngage.getAgentStatusAsync(agentOnline => {
      if (agentOnline) {
        window.console.log(`SnapEngage.agentOnline at ${new Date()}`);
        $(document).trigger("SnapEngage.agentOnline");
      } else {
        window.console.log(`SnapEngage.agentOffline at ${new Date()}`);
        $(document).trigger("SnapEngage.agentOffline");
      }
    });
  } else {
    window.console.log(`SnapEngage.agentOffline at ${new Date()}`);
    $(document).trigger("SnapEngage.agentOffline");
  }

  setTimeout(pollSnapEngageAgentOnlineStatus, 120000);
}

function disableProactiveChat() {
  window.SnapEngage.allowProactiveChat(false);
}

snapEngage.start = options => {
  loadSnapEngageLibrary(() => {
    fixBodyText();
    pollSnapEngageAgentOnlineStatus();
    $(document).trigger("SnapEngage.loaded");
    if (options.textSentToChat) {
      window.SnapEngage.sendTextToChat(options.textSentToChat);
    }
  });

  $(document).on("SnapEngage.loaded", () => {
    $(".js-open-snapengage-on-click").each((i, el) => {
      $(el).click(window.SnapEngage.startLink);
    });

    if (!options.proactiveChatEnabled) {
      disableProactiveChat();
    }
  });

  $(document).on("SnapEngage.agentOnline", () => {
    $(".js-showhide-based-on-snapengage").fadeIn();
  });

  $(document).on("SnapEngage.agentOffline", () => {
    $(".js-showhide-based-on-snapengage").fadeOut();
  });

  $(document).on("SnapEngage.agentOnline", () => {
    $(".snapengage-toggle-link").each(() => {
      const $this = $(this);
      const $data = $this.data();
      const defaultLabel = "Live Chat";
      const defaultIconClass = "icon-comments";
      const { label = defaultLabel, icon: iconClass = defaultIconClass } = $data.online;

      if ($this.find('i[class^="icon-"]').length) {
        $this.empty().append(`<i class="${iconClass}"></li>`).append(label);
      } else {
        $this.empty().append(label);
      }
    });
    $(".snapengage-chat-link").each(() => {
      const $this = $(this);
      $this.on("click", window.SnapEngage.startLink);
      $this.fadeIn();
    });
  });
  $(document).on("SnapEngage.agentOffline", () => {
    $(".snapengage-toggle-link").each(() => {
      const $this = $(this);
      const $data = $this.data();
      const defaultLabel = "Email Us";
      const defaultIconClass = "icon-envelope";
      const { label = defaultLabel, icon: iconClass = defaultIconClass } = $data.online;
      if ($this.find('i[class^="icon-"]').length) {
        $this.empty().append(`<i class="${iconClass}"></li>`).append(label);
      } else {
        $this.empty().append(label);
      }
    });
    $(".snapengage-chat-link").each(() => {
      const $this = $(this);
      $this.fadeOut();
      $this.off("click");
    });
  });
};

export default snapEngage;
