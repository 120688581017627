// this script is loaded on every page of the site.
import "core-js/stable";
import "regenerator-runtime/runtime";

import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/carousel";
import $ from "jquery";
import csrfToken from "common/csrf-token";
import "layout/modernizr_custom_tests";
import pixelService from "layout/pixel-service";
import snapEngage from "layout/snap-engage";
import pageData from "common/page-data";
import Modernizr from "modernizr";
import mixpanel from "common/mixpanel";
import render from "common/render-component";
import CookieNotice from "layout/cookie-notice";
import GlobalBanner from "layout/global-banner";
import "common/local-storage-polyfill";
import "modals/login";
import "common/bugsnag";

render(CookieNotice, "#cookie-notice");
render(GlobalBanner, "#global-banner");

$("#accordionNav")
  .on("shown.bs.collapse", e => {
    if (e.target.id === "accordionNav") {
      $("span.navbar-toggler-icon")
        .removeClass("navbar-toggler-icon")
        .addClass("header__close-icon");
    }
    return false;
  })
  .on("hidden.bs.collapse", e => {
    if (e.target.id === "accordionNav") {
      $("span.header__close-icon")
        .removeClass("header__close-icon")
        .addClass("navbar-toggler-icon");
    }
    return false;
  });

// Removes any FontAwesome icons from the $button and adds a loading spinner icon
function replaceIconWithSpinner($button) {
  const iconClass = "mx-2 fa fa-spin fa-spin fa-spinner";
  const $icon = $(`<i class="${iconClass}" />`);
  const $foundIcon = $button.find(".fa");

  if ($foundIcon.length) {
    // replace other FontAwesome icons on button if they exist
    $foundIcon.removeClass().last().addClass(iconClass);
  } else {
    // add spinner icon if the button has no FontAwesome icons
    $button.append($icon);
  }
}
// To display a loading spinner on a submit button when the button's form is submitted,
// add 'form-loading' to the form's class and 'btn-loading' to the button's class.
// The button cannot be an <input type="submit" /> element. It must be a <button />.
$(".form-loading").on("submit", e => {
  const $button = $(e.target).find(".btn-loading");

  replaceIconWithSpinner($button);
});

// To display a loading spinner on a button when the button is clicked,
// add btn-only-loading' to the button's class.
$(".btn-only-loading").on("click", e => {
  replaceIconWithSpinner($(e.currentTarget));
});

const pixels = JSON.parse($("#js-pixel-data").html());
pixelService.includePixels(pixels || []);

if (pageData.get("snapEngage.enabled")) {
  snapEngage.start(pageData.get("snapEngage"));
}

if (Modernizr.md_up) {
  $(document).on("shown.bs.modal", ".modal", e => {
    $(e.target).find("[data-modalfocus]").focus();
  });
}

// Expose the mixpanel object to the window object for 3rd party integrations
window.mixpanel = mixpanel;

const mixpanelData = JSON.parse($("#flash-mixpanel").html());
Object.keys(mixpanelData).forEach(key => {
  mixpanel.track(key, mixpanelData[key]);
});
$("#flash-mixpanel").remove();

$.ajaxSetup({
  beforeSend: xhr => {
    xhr.setRequestHeader("X-CSRF-Token", csrfToken);
  }
});
